import React from 'react'

import '../css/skeleton.css'
import '../css/normalize.css'
import '../css/components.css'

function Footer(): JSX.Element {
    return (
        <>
            <div className="Footer container">
                <p>Made with <span className="heart">❤️</span></p>
            </div>
            <div className="Legal container">
                <p>Давыдова Наталья Владимировна</p><p>ИНН 662339253115</p>
                <p><a href="https://www.flaticon.com/free-icons/cake" title="cake icons">Cake icons created by Freepik - Flaticon</a></p>
            </div>
            <div className="Legal container"> </div>
        </>
    )
}

export default Footer
