import React from 'react'
import avatar from '../assets/avatar.jpeg'
import '../css/skeleton.css'
import '../css/normalize.css'
import '../css/components.css'

function Header(): React.JSX.Element {
    return (
        <div className="Header container">
            <div className="ten columns Header__inner">
                <img src={avatar} alt="avatar" className="Header__avatar"/>
                <div className="Header__inner2">
                    <h2><b>THE CAKEMOTHER</b></h2>
                </div>
            </div>
        </div>
    )
}

export default Header
