import React from "react";
import {Helmet} from "react-helmet";

function PageNotFound(): React.JSX.Element {
    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex"/>
            </Helmet>
            <div className="PageNotFound">
                <h1>404 - Page Not Found</h1>
                <p>The page you are looking for does not exist.</p>
            </div>
        </>
    );
}

export default PageNotFound;
