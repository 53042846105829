import React from 'react';

import './css/skeleton.css'
import './css/normalize.css'
import "@fontsource/montserrat/700.css";
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import {ThemeType} from "./ts";
import PageNotFound from "./components/PageNotFound"; // Specify weight

function App(): JSX.Element {
    document.body.getAttribute('data-theme');
    const [theme] = React.useState<ThemeType>((): ThemeType => 'light');

    React.useEffect(() => {
        document.body.setAttribute('data-theme', theme)
    }, [theme]);

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/qr" element={<Navigate to="/"/>}/>
                <Route path="/404" element={<PageNotFound/>}/>
                <Route path="*" element={<Navigate to="/404"/>}/>
            </Routes>
        </div>
    );
}

export default App;
