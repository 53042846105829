import {IconItemType, ItemType, SponsorsType} from '../ts';
import {BsInfoCircle, BsWhatsapp} from "react-icons/bs";
import {MdOutlinePhotoCamera} from "react-icons/md";

const items: ItemType[] = []

const sponsors: SponsorsType[] = []

const iconItems: IconItemType[] = [
    {
        "title": "Перейти на сайт",
        "subtitle": "",
        "link": "https://thecakemother.tilda.ws",
        "iconColor": "white",
        "bgColor": "linear-gradient(45deg, #FEC0CD 0%,#FE889E 100%)",
        "icon": BsInfoCircle
    },
    {
    "title": "Whatsapp",
    "subtitle": "+79818257242 | Напиши сюда",
    "link": "https://wa.me/+79818257242",
    "iconColor": "white",
    "bgColor": "#25D366",
    "icon": BsWhatsapp
    },
    {
        "title": "Фотографии",
        "subtitle": "@the.cakemother | ..или сюда",
        "link": "https://www.instagram.com/the.cakemother",
        "iconColor": "white",
        "bgColor": "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)",
        "icon": MdOutlinePhotoCamera
    },
]

export { items, sponsors, iconItems }


