import Header from "./Header";
import {iconItems, items, sponsors} from "../assets/items";
import LargeCard from "./LargeCard";
import IconCard from "./IconCard";
import Card from "./Card";
import Footer from "./Footer";
import React from "react";

function Home(): React.JSX.Element {
    return (
        <>
            <Header></Header>
            <div className="container row">
                {
                    sponsors.map((item, i: number) => {
                        return (
                            <LargeCard
                                i={i}
                                link={item.link}
                                cover={item.image}
                            >
                            </LargeCard>
                        )
                    })
                }
                {
                    iconItems.map((item, i: number) => {
                        return (
                            <IconCard
                                i={i}
                                title={item.title}
                                subtitle={item.subtitle}
                                link={item.link}
                                iconColor={item.iconColor}
                                bgColor={item.bgColor}
                                icon={item.icon}
                            >
                            </IconCard>
                        )
                    })
                }
                {
                    items.map((item, i: number) => {
                        return (
                            <Card
                                i={i}
                                title={item.title}
                                subtitle={item.subtitle}
                                link={item.link}
                                cover={item.image}
                            >
                            </Card>
                        )
                    })
                }
            </div>
            <Footer></Footer>
        </>
)
}

export default Home;
