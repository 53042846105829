import React from 'react'

import '../css/skeleton.css'
import '../css/normalize.css'
import '../css/components.css'
import {motion} from "framer-motion"
import {IIconCard} from '../ts';
import {IconContext} from "react-icons";

function IconCard(props: IIconCard): React.JSX.Element {
    const variants = {
        visible: (i: number) => ({
            opacity: 1,
            y: 0,
            transition: {
              delay: i * 0.1,
              duration: 0.5,
              ease: "easeIn",
              type: "spring",
              stiffness: 50
            },
          }),
        hidden: { opacity: 0, y: 200}
    }
    const Icon = props.icon

    return (
        <a href={props.link} target="_blank" rel="noopener noreferrer">
            <motion.div className = "Card four columns"
                initial="hidden"
                animate="visible"
                custom={props.i}
                variants={variants}>

                <div className="cover" style={{background: props.bgColor}}>
                    <IconContext.Provider value={{ color: props.iconColor, className: "icon", size: '8rem'}}>
                        <Icon/>
                    </IconContext.Provider>
                </div>
                <div className="data">
                    <h2>{props.title}</h2>
                    <p>{props.subtitle}</p>
                </div>
            </motion.div>
        </a>
    )
}

export default IconCard;
